/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';

import Responsive from '../../components/Responsive';

import i18n, { __ } from '../../i18n';
import config from '../../../../config/server.config';
import * as utils from '../../utils';

const styles = {
  footer: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'white',
    justifyContent: 'center'
  },
  button: {
    borderRadius: '40px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#FFF',
    color: '#212529',
    border: 'solid 1px #e9ecef'
  },
  mobileButton: {
    borderRadius: '40px',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: '#FFF',
    color: '#212529',
    border: 'solid 1px #e9ecef'
  },
  primary: {
    backgroundColor: '#0069FF',
    color: '#FFF'
  },
  mobileVideo: {
    width: 343,
    height: 200,
    marginTop: '20px',
    marginBottom: '20px'
  },
  desktopVideo: {
    width: 500,
    height: 270,
    marginTop: '20px',
    marginBottom: '20px',
  }
};

@inject('store') @observer
export default class SimulationFinish extends Responsive {
  static fetchData({ store }) {
    store.app.title = __('Finish');
  }

  UNSAFE_componentWillMount() {
    const { organization } = this.props.store.simulation;

    if (!organization) {
      this.props.router.push('/simulation');
    }
  }

  handleSubmit = (e) => {
    const { store, router } = this.props;
    const { entity } = store.simulation;
    e.preventDefault();

    if (entity.id) {
      router.push(`/entities/${entity.id}/messages`);
    } else {
      this.props.router.push('/');
    }
  }

  renderVideo = () => {
    const videoStyle = this.isMobile() ? styles.mobileVideo : styles.desktopVideo;
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
        <iframe
          style={{ ...videoStyle }}
          src="https://www.youtube.com/embed/b5KUo8F4YoI"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  }

  renderForm = () => (
    <div style={{ paddingBottom: 5, display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
      <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#1D1D1D', marginBottom: 15, marginTop: 0 }}>{__('All ready!')}</p>
      <p style={{ paddingRight: '15%', paddingLeft: '15%', fontSize: 15, color: '#6C6C6C', textAlign: 'center' }}>{__('Before accessing, check out all the experience that ClassApp can provide to you!')}</p>
    </div>
  )

  renderFooter = () => {
    if (this.isMobile()) {
      const mobileStore = i18n.locale === 'pt' ? {
        android: { badge: config.android.badge.pt, link: config.android.link.br },
        ios: { badge: config.ios.badge.pt, link: config.ios.link.br }
      } :
        {
          android: { badge: config.android.badge.en, link: config.android.link.us },
          ios: { badge: config.ios.badge.en, link: config.ios.link.us }
        };

      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {utils.isIOSDevice() ?
            <a target="_blank" rel="noreferrer noopener" href={`${mobileStore.ios.link}?mt=8`} style={{ display: 'inline-block', overflow: 'hidden', background: `url(${mobileStore.ios.badge}?releaseDate=2014-10-31&kind=iossoftware&bubble=ios_apps) no-repeat`, width: '135px', height: '40px' }} />
            : <a target="_blank" rel="noreferrer noopener" href={`${mobileStore.android.link}`}><img style={{ width: '135px', height: '60px' }} alt="Get it on Google Play" src={mobileStore.android.badge} /></a>}
        </div>);
    }

    return (
      <div style={{ ...styles.footer }}>
        <Button fluid style={{ ...styles.button, ...styles.primary }} onClick={this.handleSubmit}>
          <span style={{ fontSize: '15px' }}>{__('Let\'s go')}</span>
        </Button>
      </div>);
  }

  render() {
    return (
      <div
        id="DemoFinish" style={{
          height: '100%',
          maxWidth: '460px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center'
        }}
      >
        {this.renderForm()}
        {this.renderVideo()}
        {this.renderFooter()}
      </div>
    );
  }
}
